
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$sabesp-energy-fe-primary: mat-palette($mat-indigo);
$sabesp-energy-fe-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$sabesp-energy-fe-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$sabesp-energy-fe-theme: mat-light-theme($sabesp-energy-fe-primary, $sabesp-energy-fe-accent, $sabesp-energy-fe-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($sabesp-energy-fe-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

* {
	font-family: 'Quicksand', Roboto, "Helvetica Neue", sans-serif;
	scrollbar-width: thin;
	scrollbar-color: #a7a7a7 transparent;
}
*::-webkit-scrollbar {
	width: 5px;
}

*::-webkit-scrollbar-track {
	background: transparent;
}

*::-webkit-scrollbar-thumb {
	background-color: #a7a7a7;
	border: 1px solid transparent;
}

body {
	margin: 0;
}

// Same style as PrimeNG
.blue-btn {
	background: #2196F3;
	border: 1px solid #2196F3;
}

.red-btn {
	background: #c02929;
	border: 1px solid #c02929;
}

.btn {
	border-radius: 2rem !important; // Mat-raised button replace
	color: #ffffff;
	font-size: 1rem;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.blue-btn:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 .2rem #a6d5fa;
}

.red-btn:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 .2rem #ef9696;
}

// Rewrite rules
.mat-chip {
	font-size: 11px !important; // Smaller font
}

.mat-standard-chip {
	// Resize
	padding: 0px 5px !important;
	min-height: 20px !important;
}

// Columns spacing and make border more visible
.columns-style {
	margin: 20px;
	border: #80808080 1px solid;
}

// Make font readable
.mat-input-element:disabled {
	color: black;
}

// Make columns flex
.mat-columns {
	display: flex;
	text-align: left !important;
}

// For error messages
.msg-error {
	color: red;
}

// Center content
.mat-cell {
	text-align: center;
	justify-content: center;
}

// Color icons
.icon-color {
	text-shadow: 0px 1px 1px #a5a5a5;
}

.edit:hover {
	color: #ebc634;
	outline: 0;
}

.close:hover {
	color: #ba003e;
	outline: 0;
}

.save:hover {
	color: #5acc18;
	outline: 0;
}

.back:hover {
	color: #4C8EC1;
	outline: 0;
}

// Disable hover buttons
button[disabled]:hover {
	pointer-events: none;
}

.fade {
	-webkit-animation: fade 1s;
	animation: fade 1s;
	-moz-animation: fade 1s;
	-o-animation: fade 1s;
}

.fade2 {
	-webkit-animation: fade 3s;
	animation: fade 3s;
	-moz-animation: fade 3s;
	-o-animation: fade 3s;
}

@-webkit-keyframes fade {
	0% {
			opacity: 0
	}
	50% {
			opacity: 50
	}
	100% {
			opacity: 100
	}
}

@-moz-keyframes fade {
	0% {
			opacity: 0
	}
	50% {
			opacity: 50
	}
	100% {
			opacity: 100
	}
}

@keyframes fade {
	0% {
			opacity: 0
	}
	50% {
			opacity: 50
	}
	100% {
			opacity: 100
	}
}

@-o-keyframes fade {
	0% {
			opacity: 0
	}
	50% {
			opacity: 50
	}
	100% {
			opacity: 100
	}
}